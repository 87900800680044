<template>
  <div class="container-some-error">
      <feather-icon
          icon="XIcon"
          size="15"
          class="close-toast"
          @click="closeToast"
      />
      <span class="spanAlert" v-if="!payment_data">
        Para poder retirar dinero a tu cuenta es necesario primero verificar tu email
      </span>

      <span class="spanAlert" v-else>
        Para poder retirar dinero a tu cuenta es necesario tus datos bancarios
      </span>

    <button
      class="buttonAlert"
      @click="(is_verified = true), resend({ email: userData.email })"
      v-if="!payment_data"
    >
      Enviar email de verificación
    </button>

    <button v-else @click="redirectTo()" class="buttonAlert">
      Ir a información de pago
    </button>
  </div>
</template>

<script>
import service from "@/services/others";
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  methods: {
    resend(data) {
      //this.isVerified = true;
      service.sendEmailPass(data, true).then(() => {
        loadToastificationContent().then((component) => {
          this.$toast({
            component: component.default,
            position: 'top-right',
            props: {
              title: 'Correo enviado',
              icon: "SendIcon",
              variant: "success",
            }
          })
        })
        this.$emit('close-toast')
      });
    },
    redirectTo() {
      window.location.href = '/pages/account-setting/payment-data/'
    },
    closeToast() {
      this.$emit('close-toast')
    } 
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
    is_verified: {
      type: Boolean,
      required: true,
    },
    payment_data: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isVerified: this.userData.is_verified,
    };
  },
};
</script>

<style scoped >
.container-some-error {
  width: 100%;
}
.close-toast {
  position: absolute;
  right: 3px;
  top: 17px;
  color: rgb(155, 155, 155);
  margin: 0 1.3em 0 0;
}
.close-toast:hover {
  background-color: rgba(219, 219, 219, 0.5);
  border-radius: 50%;
  cursor: pointer;
}
.strongAlert {
  display: block;
  width: 100%;
}
.spanAlert {
  display: flex;
  padding: 5px 5px 10px 0px;
}
.buttonAlert {
  padding: 5px;
  color: white;
  font-weight: bold;
  background-color: #e75455;
  border-radius: 5px;
  border: 0px;
}

.buttonAlert:hover {
  box-shadow: 1px 5px 10px #ccc;
}
.buttonAlert:focus {
  outline: none;
}
</style>
