<template>
  <b-row>
    <b-col>
      <b-card body-class="p-1" class="d-flex align-items-end">
        <b-button 
          :disabled="proposals_paid.length === 0"
          variant="outline-secondary"
          class="button-filters"
          @click="show_sider_filter = true"
        >
          <feather-icon icon="FilterIcon"></feather-icon>
          {{$t('creator.filterButton')}}
        </b-button>
        <b-button 
          :disabled="filters_layout.length === 0"
          variant="outline-secondary"
          class="button-filters"
          @click="getData()"
        >
          <feather-icon icon="Trash2Icon"/>
          {{$t('creator.clearFilter')}}

        </b-button>
      </b-card>
      <div class="scrollable-table">
        <div v-if="proposals_paid.length > 0 && format_data.length > 0">
          <b-table
            :items="format_data"
            id="table-searcher"
            :fields="$t(`creator.table_proposals.orders`)"
            head-variant="light"
            @row-clicked="rowTableClick"
            :current-page="current_page"
            :per-page="per_page"
          >
            <template #head(id)>
              <div class="text-center">ID</div>
            </template>

            <template #cell(id)="data">
              <div class="text-center">
                {{data.item.id}}
              </div>
            </template>
            <template #cell(proposal)="data">
              <div class="d-flex align-items-center">
                <b-avatar
                  :src="data.item.proposal.main_image"
                  size="45"
                  variant="light-secondary"
                  square
                  class="avatar-proposal-table"
                  :icon="isVideo(data.item.proposal.main_image) ? 'camera-video' : ''"
                />
                <div>
                  <strong>{{data.item.proposal.title}}</strong>
                  <p class="text-muted m-0">
                    {{$t(`creator.type_of_work`).find((type) => type.value === data.item.proposal.work_type).text}}
                  </p>
                </div>
              </div>
            </template>
            <template #head(order_id)="data">
              <div class="text-center">{{data.field.label}}</div>
            </template>
            <template #cell(order_id)="data">
              <div class="text-center">
                {{data.item.order_id}}
              </div>
            </template>

            <template #head(status)="data">
              <div class="text-center">{{data.field.label}}</div>
            </template>
            <template
              #cell(order_status)="data"
            >
              <div class="text-center">
                <b-badge :variant="colorStatus(data.item.order_status[data.item.order_status.length - 1].is_completed)" v-if="data.item.order_status.length > 0">
                  {{$t(`creator.status_orders.${data.item.order_status[data.item.order_status.length - 1].status}`)}} - {{ data.item.order_status[data.item.order_status.length - 1].is_completed ? $t('creator.completed') : $t('creator.incompleted') }}</b-badge>
                
                <b-badge v-else variant="light-secondary-v2" class="light-secondary-v2">-</b-badge>
              </div>
            </template>
            <template #head(date)="data">
              <div class="text-center">{{data.field.label}}</div>
            </template>
            <template #cell(created_at)="data">
              <div class="text-center">
                {{data.item.created_at}}
              </div>
            </template>
          </b-table>
          <b-pagination 
            v-model="current_page" 
            :total-rows="format_data.length" 
            :per-page="per_page"
            class="d-flex justify-content-center"
          ></b-pagination>
        </div>
        <template v-else>
          <no-Results
            :title= "$t('creator.messageNoResultsOrders')"
            :show_button= "false"
          />
        </template>
      </div>
    </b-col>
    <div class="container-loader-searcher loader-table-cards" v-if="!loader_orders">
      <b-spinner class="spinner-table-searcher"></b-spinner>
    </div>
    <b-sidebar right shadow backdrop v-model="open_sidebar" class="sidebar-orders-work">
      <template #header>
        <div class="w-100 d-flex justify-content-between align-items-center p-1" v-if="open_sidebar">
          <b-link class="link-go-chat" :to="{name: 'chat-proposal', params: {chat_uuid: data_for_sidebar.chat_uuid }}" target="_blank">
            <feather-icon icon="ExternalLinkIcon" class="mr-05"></feather-icon>
            {{ $t('creator.goChat') }}
          </b-link>
          <feather-icon 
            icon="XIcon" 
            size="18" 
            class="close-icon-sidebar-orders"
            @click="open_sidebar = false"
          >
          </feather-icon>
        </div>
      </template>
      <div class="p-1" v-if="open_sidebar">
        <state-sale :chat_dad="proposals_paid.find(i => i.uuid === data_for_sidebar.chat_uuid)" v-if="open_sidebar" :is_invoice="true"></state-sale>
        <h5 class="ml-1 mb-1" v-if="data_for_sidebar.order_status.length > 0">{{ $t('creator.statusOrder') }}</h5>
        <state-sale :chat_dad="proposals_paid.find(i => i.uuid === data_for_sidebar.chat_uuid)" v-if="open_sidebar" :no_header="true"></state-sale>
        <div v-if="isNotTransaction()" class="ml-1">
          {{ $t('creator.noTransaction') }}
        </div>
      </div>
    </b-sidebar>
    <sider-order-filter
      class="open-sidebar-search"
      :filters_layout="filters_layout"
      @close_siderbar_filter="closeSiderFilter"
      :show_sider_filter="show_sider_filter"
      @update_order="getFormatTable"
      v-if="show_sider_filter"
    />
  </b-row>
</template>

<script>
import {
  BCard,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BSidebar,
  BLink,
  BPagination,
} from 'bootstrap-vue';

import chat_service from '@/services/chat'
import StateSale from '@/views/components/chat/StateSale.vue';
import siderOrderFilter  from '@/views/pages/proposals/stepper/siderOrderFilter.vue'
import { isVideo } from '@/libs/utils/formats';
import { getAssetUrl } from '@/libs/utils/urls';
import { getDatev2 } from '@/libs/utils/times';

export default {
  name: 'orderProposals',
  components: {
    BCard,
    BButton,
    BSpinner,
    noResults: () => import('@/views/pages/proposals/stepper/noResults.vue'),
    BRow,
    BCol,
    BTable,
    BAvatar,
    BBadge,
    BSidebar,
    BLink,
    BPagination,
    StateSale,
    siderOrderFilter,
  },
  data() {
    return {
      isVideo,
      loader_orders: false,
      proposals_paid: [],
      open_sidebar: false,
      data_for_sidebar: {},
      show_sider_filter: false,
      format_data: [],
      filters_layout: [],
      current_page: 1,
      per_page: 10
    }
  },
  created() {
    this.getData();
  },
  methods: {
    colorStatus(is_completed) {
      if (is_completed === true) return 'light-success'
      if (is_completed === false) return 'light-warning'
      return 'light-secondary'
    },
    closeSiderFilter() {
      this.show_sider_filter = false
    },
    getFormatTable(proposals, filters) {
      if (filters !== undefined) {
        this.filters_layout = filters
      }
      this.format_data = []
      let index = 1;
      proposals.map((item) => {
        if (item.proposal !== null) { 
          let first_image = {}
          if (item.proposal.images.length > 0) {
            first_image = item.proposal.images.reduce((prev, curr) => {
              return prev.sort < curr.sort ? prev : curr;
            });
          }
          this.format_data.push({
            id: index++,
            proposal: {
              main_image: item.proposal.images.length > 0 ? !first_image.media ? getAssetUrl(first_image.media) : first_image.media : require("@/assets/images/dashboard/imgDefault.png"),
              title: item.proposal.title,
              work_type: item.proposal.work_type
            },
            order_id: item.user_transaction ? item.user_transaction.order_id : '-',
            order_status: item.order_status,
            created_at: getDatev2(new Date(item.created_at)),
            chat_uuid: item.uuid,
          });
        }
      });
    },
    getData() {
      this.loader_orders = false;
      this.filters_layout = []
      chat_service.getChats({ has_sort: true }).then((response) => {
        this.proposals_paid = response.results;
        this.loader_orders = true;
        this.getFormatTable(this.proposals_paid)
      });
    },
    rowTableClick(row) {
      this.data_for_sidebar = row;
      this.open_sidebar = true;
    },
    isNotTransaction() {
      return this.data_for_sidebar.order_status.length === 0 && !this.data_for_sidebar.user_proposal && !this.data_for_sidebar.user_transaction
    },
  }
}
</script>
<style scoped>
.link-go-chat {
  display: flex;
  align-items: center;
}
</style>
<style>
  .sidebar-orders-work > .b-sidebar {
    width: 420px !important;
  }
  .sidebar-orders-work .b-sidebar-header {
    font-size: 14px !important;
  }
  .close-icon-sidebar-orders:hover {
    background-color: rgba(219, 219, 219, 0.5);
    border-radius: 50%;
    cursor: pointer;
  }
  .container-loader-searcher {
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: rgba(255, 255, 255);
    z-index: 1000;
    right: 5px;
  }
  .loader-table-cards {
    z-index: 10;
    display: flex;
    justify-content: center;
  }
  .spinner-table-searcher {
    margin-top: 14em;
    height: 6em;
    width: 6em;
    color: #acacac;
  }
</style>

