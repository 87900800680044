<template>
  <b-sidebar 
    v-model="show" 
    @change="closeSider" 
    right 
    shadow 
    backdrop 
    bg-variant="white"
    header-class="container-title-sider"
    width="400px"
  >
    <template #header>
      <div>
        <span class="title-sider">{{ $t('creator.titleSiderFilter') }}</span>
        <feather-icon 
          class="close-sider-network" 
          icon="XIcon" 
          size="20" 
          @click="show = false" 
        />
      </div>
    </template>
        <div class="mt-2 px-2 mb-2">
      <div>
        <b-form-group class="text-muted"
          :label="$t('creator.typeFilter')"
        >
            <b-form-checkbox v-model="type_transaction" value="proposal" class="content-checkbox">{{ $t('creator.proposal') }}</b-form-checkbox>
            <b-form-checkbox v-model="type_transaction" value="campaign" class="content-checkbox">{{ $t('creator.campaign_marketplace') }}</b-form-checkbox>
            <b-form-checkbox v-model="type_transaction" value="casting_call"  class="content-checkbox">{{ $t('creator.casting_call') }}</b-form-checkbox>
            <b-form-checkbox v-model="type_transaction" value="legacy"  class="content-checkbox">{{ $t('creator.legacy') }}</b-form-checkbox>
        </b-form-group>
      
        <b-form-group class="text-muted" :label="$t('creator.dateFilter')">
          <div class="fields">
            <b-form-datepicker
              v-model="date_start"
              class="size-inputs"
              :placeholder="$t('creator.startDate')" 
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="es"
            />
            <b-form-datepicker
              v-model="date_end"
              class="size-inputs"
              :placeholder="$t('creator.endDate')"
              :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
              locale="es"
            />
          </div>
        </b-form-group>
        <b-form-group class="text-muted"
          :label="$t('creator.statusFilter')"
        >
            <b-form-checkbox v-model="status" value="paid" class="content-checkbox">{{ $t('creator.paid') }}</b-form-checkbox>
            <b-form-checkbox v-model="status" value="unpaid" class="content-checkbox">{{ $t('creator.unpaid') }}</b-form-checkbox>
            <b-form-checkbox v-model="status" value="program_withdrawal" class="content-checkbox">{{ $t('creator.program_withdrawal') }}</b-form-checkbox>
            <b-form-checkbox v-model="status" value="withdrawal" class="content-checkbox">{{ $t('creator.withdrawal') }}</b-form-checkbox>
            <b-form-checkbox v-model="status" value="rejected" class="content-checkbox">{{ $t('creator.rejected') }}</b-form-checkbox>
        </b-form-group>
        <div class="d-flex mb-2">
          <b-form-group
            :label="$t('creator.minAmount')"
          >
            <cleave
              :options="options_cleave.number"
              v-model="amount_min"
              class="form-control"
              placeholder="$ 50.00"  
            />
          </b-form-group>
          <b-form-group
            :label="$t('creator.maxAmount')"
            class="ml-1"
          >
            <cleave
              :options="options_cleave.number"
              v-model="amount_max"
              class="form-control"
              placeholder="$ 500.00"  
            />
          </b-form-group>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="button-spacing">
        <b-button class="btn-clear" variant="outline-secondary" @click="resetForm()">
          <b-icon icon="trash" class="cursor-pointer" />
        </b-button>
        <b-button class="size-button" variant="primary" @click="applyFilters()">
         {{ $t('creator.updateResultFilters') }}
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>
    
<script>
import {
  BIcon,
  BSidebar,
  BFormGroup,
  BFormCheckbox,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import others from "@/services/others";
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import cleave from 'vue-cleave-component';
export default {
  name: "SiderFilter",
  components: {
    cleave,
    BIcon,
    BSidebar,
    BFormGroup,
    BFormCheckbox,
    BFormDatepicker,
    BButton,
  },
  props: {
    show_sider_filter: {
      type: Boolean,
    },
    filters_layout: {
      type: Array,
    },
  },
  data() {
    return {
      options_cleave: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      },
      show: this.show_sider_filter,
      status: '',
      type_transaction: '',
      date_start: '',
      date_end: '',
      amount_min: '',
      amount_max: '',
      filters: [
        {
          filter: 'type_transaction',
          value: '',
        },
        {
          filter: 'date_transaction',
          value: {minDate: '', maxDate: ''},
        },
        {
          filter: 'status_transaction',
          value: '',
        },
        {
          filter: 'amount_transaction',
          value: {minAmount: '', maxAmount: ''},
        }
      ],
    };
  },
  created() {
    if (this.filters_layout.length > 0) {
      this.type_transaction = this.filters_layout[0].value;
      this.date_start = this.filters_layout[1].value.minDate;
      this.date_end = this.filters_layout[1].value.maxDate;
      this.status = this.filters_layout[2].value;
      this.amount_min = this.filters_layout[3].value.minAmount;
      this.amount_max = this.filters_layout[3].value.maxAmount;
    }
  },
  methods: {
    resetForm() {
      this.status = '';
      this.type_transaction = '';
      this.date_start = '';
      this.date_end = '';
      this.amount_min = '';
      this.amount_max = '';
      this.applyFilters()
    },
    closeSider() {
      this.$emit("close_siderbar_filter");
    },
    applyFilters () {
      const filteredData = {};
      
      // Agregar solo los filtros que tienen datos
      if (this.type_transaction !== '') {
        if (this.type_transaction) {
          filteredData.type_transaction = this.filters[0].value = this.type_transaction;
        } else {
          this.type_transaction = this.filters[0].value = ''
        }
      }
      if (this.date_start !== '') filteredData.date_start = this.filters[1].value.minDate = this.date_start;
      if (this.date_end !== '') filteredData.date_end = this.filters[1].value.maxDate = this.date_end;
      if (this.status !== '') {
        if (this.status) {
          filteredData.status = this.filters[2].value = this.status;
        } else {
          this.status = this.filters[2].value = ''
        }
      }
      if (this.amount_min !== '') filteredData.amount_min = this.filters[3].value.minAmount = this.amount_min;
      if (this.amount_max !== '') filteredData.amount_max = this.filters[3].value.maxAmount = this.amount_max;
      
      others.transactionsWithFilters(filteredData)
        .then(response => {
          if (response.status === 400) {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title:  this.$t('err'),
                  text: this.$t('messageAccountSettingError'),
                  icon: "CoffeeIcon",
                  variant: "danger",
                }
              })
            })
          } else {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                position: 'top-right',
                props: {
                  title: this.$t('creator.filterApply'),
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text:  this.$t('creator.textfilter')
                }
              })
            })
            this.$emit("update_transaction", response.results, this.filters);
            this.show = false
          }
        })
    },
  }
};
</script>
<style>
.size-inputs {
  width: 45%;
}
.fields {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.content-checkbox {
  margin-bottom: 2px;
}
.button-spacing {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.size-button {
  width: 75%;
  height: 40px;
}
.btn-clear {
  width: 20%;
}
.btn-clear:focus {
  width: 20%;
  background: transparent !important;
}
</style>