<template>
  <b-sidebar
    v-model="show"
    @change="closeSider"
    right
    shadow
    backdrop
    header-class="container-title-sider"
    width="400px"
    bg-variant="white"
  >
    <template #header>
      <div>
        <span class="title-sider">{{ $t("creator.titleSiderOrder") }}</span>
        <feather-icon
          class="close-sider-network"
          icon="XIcon"
          size="20"
          @click="show = false"
        />
      </div>
    </template>

    <div class="mt-2 px-2 mb-2">
      <div>
        <b-form-group class="text-muted" :label="$t('creator.statusFilter')">
          <b-form-checkbox
            v-model="status"
            value="paid"
            class="content-checkbox"
            >{{ $t("creator.paid") }}</b-form-checkbox
          >
          <b-form-checkbox
            v-model="status"
            value="unpaid"
            class="content-checkbox"
            >{{ $t("creator.unpaid") }}</b-form-checkbox
          >
          <b-form-checkbox
            v-model="status"
            value="program_withdrawal"
            class="content-checkbox"
            >{{ $t("creator.program_withdrawal") }}</b-form-checkbox
          >
          <b-form-checkbox
            v-model="status"
            value="withdrawal"
            class="content-checkbox"
            >{{ $t("creator.withdrawal") }}</b-form-checkbox
          >
          <b-form-checkbox
            v-model="status"
            value="rejected"
            class="content-checkbox"
            >{{ $t("creator.rejected") }}</b-form-checkbox
          >
        </b-form-group>
        <b-form-group class="text-muted" :label="$t('creator.dateFilter')">
          <div class="fields">
            <b-form-datepicker
              v-model="date_start"
              class="size-inputs"
              :placeholder="$t('creator.startDate')"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="es"
            />
            <b-form-datepicker
              v-model="date_end"
              class="size-inputs"
              :placeholder="$t('creator.endDate')"
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="es"
            />
          </div>
        </b-form-group>

        <b-form-group class="text-muted"  :label="$t('creator.statusOrder')">
          <b-form-checkbox
            v-model="status_order"
            value="founded"
            class="content-checkbox"
            >{{ $t("creator.founded") }}</b-form-checkbox
          >
          <b-form-checkbox
            v-model="status_order"
            value="accepted"
            class="content-checkbox"
            >{{ $t("creator.accepted") }}</b-form-checkbox
          >
          <b-form-checkbox
            v-model="status_order"
            value="review"
            class="content-checkbox"
            >{{ $t("creator.review") }}</b-form-checkbox
          >
          <b-form-checkbox
            v-model="status_order"
            value="declined"
            class="content-checkbox"
            >{{ $t("creator.declined") }}</b-form-checkbox
          >
          <b-form-checkbox
            v-model="status_order"
            value="refunded"
            class="content-checkbox"
            >{{ $t("creator.refunded") }}</b-form-checkbox
          >
        </b-form-group>

        <div class="d-flex mb-2">
          <b-form-group
            :label="$t('creator.minAmount')"
          >
            <cleave
              :options="options_cleave.number"
              v-model="amount_min"
              class="form-control"
              placeholder="$ 50.00"  
            />
          </b-form-group>
          <b-form-group
            :label="$t('creator.maxAmount')"
            class="ml-1"
          >
            <cleave
              :options="options_cleave.number"
              v-model="amount_max"
              class="form-control"
              placeholder="$ 500.00"  
            />
          </b-form-group>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="button-spacing">
        <b-button class="btn-clear" variant="outline-secondary" @click="resetForm()">
          <b-icon icon="trash" class="cursor-pointer" />
        </b-button>
        <b-button class="size-button" variant="primary" @click="applyFilters()">
         {{ $t('creator.updateResultFilters') }}
        </b-button>
      </div>
    </template>
  </b-sidebar>
</template>
      
<script>
import {
  BIcon,
  BSidebar,
  BFormGroup,
  BFormCheckbox,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import chat_service from '@/services/chat'
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import cleave from "vue-cleave-component";

export default {
  name: "siderOrderFilter",
  components: {
    cleave,
    BIcon,
    BSidebar,
    BFormGroup,
    BFormCheckbox,
    BFormDatepicker,
    BButton,
  },
  props: {
    show_sider_filter: {
      type: Boolean,
    },
    filters_layout: {
      type: Array,
    }
  },
  data() {
    return {
      options_cleave: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      show: this.show_sider_filter,
      status: "",
      status_order: "",
      date_start: "",
      date_end: "",
      amount_min: "",
      amount_max: "",
      only_sales: true,
      filters: [
        {
          filter: 'status_order',
          value: '',
        },
        {
          filter: 'date_order',
          value: {minDate: '', maxDate: ''},
        },
        {
          filter: 'status',
          value: '',
        },
        {
          filter: 'amount_order',
          value: {minAmount: '', maxAmount: ''},
        }
      ],
    };
  },
  created() {
    if (this.filters_layout.length > 0) {
      this.status_order = this.filters_layout[0].value;
      this.date_start = this.filters_layout[1].value.minDate;
      this.date_end = this.filters_layout[1].value.maxDate;
      this.status = this.filters_layout[2].value;
      this.amount_min = this.filters_layout[3].value.minAmount;
      this.amount_max = this.filters_layout[3].value.maxAmount;
    }
  },
  methods: {
    resetForm() {
      this.status = "";
      this.status_order = "";
      this.date_start = "";
      this.date_end = "";
      this.amount_min = "";
      this.amount_max = "";
      this.applyFilters()
    },
    closeSider() {
      this.$emit("close_siderbar_filter");
    },
    applyFilters() {
      const filteredData = {};
      
      if (this.status_order !== '') {
        if (this.status_order) {
          filteredData.status_order = this.filters[0].value = this.status_order;
        } else {
          this.status_order = this.filters[0].value = ''
        }
      }
      if (this.date_start !== '') filteredData.date_start = this.filters[1].value.minDate = this.date_start;
      if (this.date_end !== '') filteredData.date_end = this.filters[1].value.maxDate = this.date_end;
      if (this.status !== '') {
        if (this.status) {
          filteredData.status = this.filters[2].value = this.status;
        } else {
          this.status = this.filters[2].value = ''
        }
      }
      if (this.amount_min !== '') filteredData.amount_min = this.filters[3].value.minAmount = this.amount_min;
      if (this.amount_max !== '') filteredData.amount_max = this.filters[3].value.maxAmount = this.amount_max;
      if (Object.keys(filteredData).length > 0) filteredData.only_sales = this.only_sales;
      
      chat_service.getChats(filteredData).then((response) => {
        if (response.status === 400) {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t("err"),
                text: this.$t("messageAccountSettingError"),
                icon: "AlertCircleIcon",
                variant: "danger",
              }
            })
          })
        } else {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t("creator.filterApply"),
                icon: "CheckCircleIcon",
                variant: "success",
                text: this.$t("creator.textfilter"),
              }
            })
          })
          this.$emit("update_order", response.results, this.filters);
          this.show = false;
        }
      });
    },
  },
};
</script>
  
<style>
.size-inputs {
  width: 45%;
}

.fields {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-checkbox {
  margin-bottom: 2px;
}

.button-spacing {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.size-button {
  width: 75%;
  height: 40px;
}

.btn-clear {
  width: 20%;
}

.btn-clear:focus {
  width: 20%;
  background: transparent !important;
}
</style>
